import { defineStore } from "pinia";
import { INFRA } from "@/api/infra";
import { useSitesStore } from "./sites";

// Infra is constant, so we can fetch data from all site once, then use getter to filter by site
interface IInfraStoreState {
  roadRoads: any[];
  roadLanes: any[];
  roadZones: any[];
  busStations: any[];
  busBarriers: any[];
  busLanes: any[];
  tollStations: any[];
  tollGates: any[];
  tollLanes: any[];
  tollZones: any[];
  ubrs: any[];
  cameras: any[];
  loading: boolean;
}

export const useInfraStore = defineStore("infra", {
  state: (): IInfraStoreState => ({
    roadRoads: [],
    roadLanes: [],
    roadZones: [],
    busStations: [],
    busBarriers: [],
    busLanes: [],
    tollStations: [],
    tollGates: [],
    tollLanes: [],
    tollZones: [],
    ubrs: [],
    cameras: [],
    loading: false,
  }),
  getters: {
    getRoadRoads(state) {
      const { site } = storeToRefs(useSitesStore());
      return () =>
        state.roadRoads.filter((road) => road.site === site.value?.slug);
    },
    getRoadLanes(state) {
      const { site } = storeToRefs(useSitesStore());
      return () =>
        state.roadLanes.filter((lane) => lane.site === site.value?.slug);
    },
    getRoadZones(state) {
      const { site } = storeToRefs(useSitesStore());
      return () =>
        state.roadZones.filter((zone) => zone.site === site.value?.slug);
    },
    getTollStations(state) {
      const { site } = storeToRefs(useSitesStore());
      return () =>
        state.tollStations.filter((station) => station.site === site.value?.slug);
    },
    getCameras(state) {
      const { site } = storeToRefs(useSitesStore());
      return () =>
        state.cameras.filter((camera) => camera.site === site.value?.slug);
    },
    getCameraById(state) {
      const { site } = storeToRefs(useSitesStore());
      return (cameraId: string) =>
        state.cameras.find((camera) => (camera.site === site.value?.slug && camera.ressource_id === cameraId));
    },
    getUbrs(state) {
      const { site } = storeToRefs(useSitesStore());
      return () =>
      state.ubrs.filter((ubr) => ubr.site === site.value?.slug);
    },
    getUbrById(state) {
      const { site } = storeToRefs(useSitesStore());
      return (ubrId: string) =>
        state.ubrs.find((ubr) => (ubr.site === site.value?.slug && ubr.ressource_id === ubrId));
    }
  },
  actions: {
    async fetchRoadRoads() {
      const { sites } = storeToRefs(useSitesStore());
      this.roadRoads = [];
      for (const site of sites.value) {
        try {
          this.roadRoads.push(...await INFRA.getRoadRoads(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchRoadLanes() {
      this.roadLanes = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.roadLanes.push(...await INFRA.getRoadLanes(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchRoadZones() {
      this.roadZones = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.roadZones.push(...await INFRA.getRoadZones(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchTollStations() {
      this.tollStations = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.tollStations.push(...await INFRA.getTollStations(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchTollGates() {
      this.tollGates = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.tollGates.push(...await INFRA.getTollGates(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchTollZones() {
      this.tollZones = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.tollZones.push(...await INFRA.getTollZones(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchTollLanes() {
      this.tollLanes = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.tollLanes.push(...await INFRA.getTollLanes(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchBusStations() {
      this.busStations = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.busStations.push(...await INFRA.getBusStations(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchBusBarriers() {
      this.busBarriers = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.busBarriers.push(...await INFRA.getBusBarriers(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchBusLanes() {
      this.busLanes = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.busLanes.push(...await INFRA.getBusLanes(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchCameras() {
      this.cameras = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.cameras.push(...await INFRA.getCameras(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async fetchUbrs() {
      this.ubrs = [];
      const { sites } = storeToRefs(useSitesStore());
      for (const site of sites.value) {
        try {
          this.ubrs.push(...await INFRA.getUbrs(site.slug));
        } catch (error) {
          console.error(error);
        } finally {
          if (Array.isArray(this.ubrs)) {
            const ubr_nanterre = this.ubrs.find((ubr) => (ubr.site === "autoroutes")&& (ubr.ressource_id === "7"));
            if (ubr_nanterre){
              ubr_nanterre.region_of_interest.coordinates = [[0,0],[0,1],[1,0],[0,0]];
            }//TODO temporary fix for nanterre ubr roi (too big on the map)
          }
          this.loading = false;
        }
      }
    }
  },
});
